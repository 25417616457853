// This file was autogenerated from
// https://github.com/grafana/icons

import { cacheStore } from 'react-inlinesvg';

export let cacheInitialized = false;
export let iconRoot = 'public/img/icons';

import u1001 from '!!raw-loader!../../../../../public/img/icons/unicons/angle-double-down.svg';
import u1002 from '!!raw-loader!../../../../../public/img/icons/unicons/angle-double-right.svg';
import u1003 from '!!raw-loader!../../../../../public/img/icons/unicons/angle-down.svg';
import u1004 from '!!raw-loader!../../../../../public/img/icons/unicons/angle-left.svg';
import u1005 from '!!raw-loader!../../../../../public/img/icons/unicons/angle-right.svg';
import u1006 from '!!raw-loader!../../../../../public/img/icons/unicons/angle-up.svg';
import u1007 from '!!raw-loader!../../../../../public/img/icons/unicons/apps.svg';
import u1008 from '!!raw-loader!../../../../../public/img/icons/unicons/arrow.svg';
import u1009 from '!!raw-loader!../../../../../public/img/icons/unicons/arrow-down.svg';
import u1010 from '!!raw-loader!../../../../../public/img/icons/unicons/arrow-from-right.svg';
import u1011 from '!!raw-loader!../../../../../public/img/icons/unicons/arrow-left.svg';
import u1012 from '!!raw-loader!../../../../../public/img/icons/unicons/arrow-random.svg';
import u1013 from '!!raw-loader!../../../../../public/img/icons/unicons/arrow-right.svg';
import u1014 from '!!raw-loader!../../../../../public/img/icons/unicons/arrow-up.svg';
import u1015 from '!!raw-loader!../../../../../public/img/icons/unicons/arrows-h.svg';
import u1016 from '!!raw-loader!../../../../../public/img/icons/unicons/backward.svg';
import u1017 from '!!raw-loader!../../../../../public/img/icons/unicons/bars.svg';
import u1018 from '!!raw-loader!../../../../../public/img/icons/unicons/bell.svg';
import u1019 from '!!raw-loader!../../../../../public/img/icons/unicons/bell-slash.svg';
import u1020 from '!!raw-loader!../../../../../public/img/icons/unicons/bolt.svg';
import u1021 from '!!raw-loader!../../../../../public/img/icons/unicons/book.svg';
import u1022 from '!!raw-loader!../../../../../public/img/icons/unicons/book-open.svg';
import u1023 from '!!raw-loader!../../../../../public/img/icons/unicons/brackets-curly.svg';
import u1024 from '!!raw-loader!../../../../../public/img/icons/unicons/bug.svg';
import u1025 from '!!raw-loader!../../../../../public/img/icons/unicons/building.svg';
import u1026 from '!!raw-loader!../../../../../public/img/icons/unicons/calculator-alt.svg';
import u1027 from '!!raw-loader!../../../../../public/img/icons/unicons/calendar-alt.svg';
import u1028 from '!!raw-loader!../../../../../public/img/icons/unicons/camera.svg';
import u1029 from '!!raw-loader!../../../../../public/img/icons/unicons/channel-add.svg';
import u1030 from '!!raw-loader!../../../../../public/img/icons/unicons/chart-line.svg';
import u1031 from '!!raw-loader!../../../../../public/img/icons/unicons/check.svg';
import u1032 from '!!raw-loader!../../../../../public/img/icons/unicons/check-circle.svg';
import u1033 from '!!raw-loader!../../../../../public/img/icons/unicons/circle.svg';
import u1034 from '!!raw-loader!../../../../../public/img/icons/unicons/clipboard-alt.svg';
import u1035 from '!!raw-loader!../../../../../public/img/icons/unicons/clock-nine.svg';
import u1036 from '!!raw-loader!../../../../../public/img/icons/unicons/cloud.svg';
import u1037 from '!!raw-loader!../../../../../public/img/icons/unicons/cloud-download.svg';
import u1038 from '!!raw-loader!../../../../../public/img/icons/unicons/code-branch.svg';
import u1039 from '!!raw-loader!../../../../../public/img/icons/unicons/cog.svg';
import u1040 from '!!raw-loader!../../../../../public/img/icons/unicons/columns.svg';
import u1041 from '!!raw-loader!../../../../../public/img/icons/unicons/comment-alt.svg';
import u1042 from '!!raw-loader!../../../../../public/img/icons/unicons/comment-alt-share.svg';
import u1043 from '!!raw-loader!../../../../../public/img/icons/unicons/comments-alt.svg';
import u1044 from '!!raw-loader!../../../../../public/img/icons/unicons/compass.svg';
import u1045 from '!!raw-loader!../../../../../public/img/icons/unicons/copy.svg';
import u1046 from '!!raw-loader!../../../../../public/img/icons/unicons/cube.svg';
import u1047 from '!!raw-loader!../../../../../public/img/icons/unicons/dashboard.svg';
import u1048 from '!!raw-loader!../../../../../public/img/icons/unicons/database.svg';
import u1049 from '!!raw-loader!../../../../../public/img/icons/unicons/document-info.svg';
import u1050 from '!!raw-loader!../../../../../public/img/icons/unicons/download-alt.svg';
import u1051 from '!!raw-loader!../../../../../public/img/icons/unicons/draggabledots.svg';
import u1052 from '!!raw-loader!../../../../../public/img/icons/unicons/edit.svg';
import u1053 from '!!raw-loader!../../../../../public/img/icons/unicons/ellipsis-v.svg';
import u1054 from '!!raw-loader!../../../../../public/img/icons/unicons/envelope.svg';
import u1055 from '!!raw-loader!../../../../../public/img/icons/unicons/exchange-alt.svg';
import u1056 from '!!raw-loader!../../../../../public/img/icons/unicons/exclamation-triangle.svg';
import u1057 from '!!raw-loader!../../../../../public/img/icons/unicons/external-link-alt.svg';
import u1058 from '!!raw-loader!../../../../../public/img/icons/unicons/eye.svg';
import u1059 from '!!raw-loader!../../../../../public/img/icons/unicons/eye-slash.svg';
import u1060 from '!!raw-loader!../../../../../public/img/icons/unicons/file-alt.svg';
import u1061 from '!!raw-loader!../../../../../public/img/icons/unicons/file-blank.svg';
import u1062 from '!!raw-loader!../../../../../public/img/icons/unicons/filter.svg';
import u1063 from '!!raw-loader!../../../../../public/img/icons/unicons/folder.svg';
import u1064 from '!!raw-loader!../../../../../public/img/icons/unicons/folder-open.svg';
import u1065 from '!!raw-loader!../../../../../public/img/icons/unicons/folder-plus.svg';
import u1066 from '!!raw-loader!../../../../../public/img/icons/unicons/folder-upload.svg';
import u1067 from '!!raw-loader!../../../../../public/img/icons/unicons/forward.svg';
import u1068 from '!!raw-loader!../../../../../public/img/icons/unicons/graph-bar.svg';
import u1069 from '!!raw-loader!../../../../../public/img/icons/unicons/history.svg';
import u1070 from '!!raw-loader!../../../../../public/img/icons/unicons/home-alt.svg';
import u1071 from '!!raw-loader!../../../../../public/img/icons/unicons/import.svg';
import u1072 from '!!raw-loader!../../../../../public/img/icons/unicons/info.svg';
import u1073 from '!!raw-loader!../../../../../public/img/icons/unicons/info-circle.svg';
import u1074 from '!!raw-loader!../../../../../public/img/icons/unicons/key-skeleton-alt.svg';
import u1075 from '!!raw-loader!../../../../../public/img/icons/unicons/keyboard.svg';
import u1076 from '!!raw-loader!../../../../../public/img/icons/unicons/link.svg';
import u1077 from '!!raw-loader!../../../../../public/img/icons/unicons/list-ul.svg';
import u1078 from '!!raw-loader!../../../../../public/img/icons/unicons/lock.svg';
import u1079 from '!!raw-loader!../../../../../public/img/icons/unicons/minus.svg';
import u1080 from '!!raw-loader!../../../../../public/img/icons/unicons/minus-circle.svg';
import u1081 from '!!raw-loader!../../../../../public/img/icons/unicons/mobile-android.svg';
import u1082 from '!!raw-loader!../../../../../public/img/icons/unicons/monitor.svg';
import u1083 from '!!raw-loader!../../../../../public/img/icons/unicons/pause.svg';
import u1084 from '!!raw-loader!../../../../../public/img/icons/unicons/pen.svg';
import u1085 from '!!raw-loader!../../../../../public/img/icons/unicons/play.svg';
import u1086 from '!!raw-loader!../../../../../public/img/icons/unicons/plug.svg';
import u1087 from '!!raw-loader!../../../../../public/img/icons/unicons/plus.svg';
import u1088 from '!!raw-loader!../../../../../public/img/icons/unicons/plus-circle.svg';
import u1089 from '!!raw-loader!../../../../../public/img/icons/unicons/power.svg';
import u1090 from '!!raw-loader!../../../../../public/img/icons/unicons/presentation-play.svg';
import u1091 from '!!raw-loader!../../../../../public/img/icons/unicons/process.svg';
import u1092 from '!!raw-loader!../../../../../public/img/icons/unicons/question-circle.svg';
import u1093 from '!!raw-loader!../../../../../public/img/icons/unicons/repeat.svg';
import u1094 from '!!raw-loader!../../../../../public/img/icons/unicons/rocket.svg';
import u1095 from '!!raw-loader!../../../../../public/img/icons/unicons/save.svg';
import u1096 from '!!raw-loader!../../../../../public/img/icons/unicons/search.svg';
import u1097 from '!!raw-loader!../../../../../public/img/icons/unicons/search-minus.svg';
import u1098 from '!!raw-loader!../../../../../public/img/icons/unicons/search-plus.svg';
import u1099 from '!!raw-loader!../../../../../public/img/icons/unicons/share-alt.svg';
import u1100 from '!!raw-loader!../../../../../public/img/icons/unicons/shield.svg';
import u1101 from '!!raw-loader!../../../../../public/img/icons/unicons/signal.svg';
import u1102 from '!!raw-loader!../../../../../public/img/icons/unicons/signin.svg';
import u1103 from '!!raw-loader!../../../../../public/img/icons/unicons/signout.svg';
import u1104 from '!!raw-loader!../../../../../public/img/icons/unicons/sitemap.svg';
import u1105 from '!!raw-loader!../../../../../public/img/icons/unicons/slack.svg';
import u1106 from '!!raw-loader!../../../../../public/img/icons/unicons/sliders-v-alt.svg';
import u1107 from '!!raw-loader!../../../../../public/img/icons/unicons/sort-amount-down.svg';
import u1108 from '!!raw-loader!../../../../../public/img/icons/unicons/sort-amount-up.svg';
import u1109 from '!!raw-loader!../../../../../public/img/icons/unicons/square-shape.svg';
import u1110 from '!!raw-loader!../../../../../public/img/icons/unicons/star.svg';
import u1111 from '!!raw-loader!../../../../../public/img/icons/unicons/step-backward.svg';
import u1112 from '!!raw-loader!../../../../../public/img/icons/unicons/sync.svg';
import u1113 from '!!raw-loader!../../../../../public/img/icons/unicons/table.svg';
import u1114 from '!!raw-loader!../../../../../public/img/icons/unicons/tag-alt.svg';
import u1115 from '!!raw-loader!../../../../../public/img/icons/unicons/times.svg';
import u1116 from '!!raw-loader!../../../../../public/img/icons/unicons/trash-alt.svg';
import u1117 from '!!raw-loader!../../../../../public/img/icons/unicons/unlock.svg';
import u1118 from '!!raw-loader!../../../../../public/img/icons/unicons/upload.svg';
import u1119 from '!!raw-loader!../../../../../public/img/icons/unicons/user.svg';
import u1120 from '!!raw-loader!../../../../../public/img/icons/unicons/users-alt.svg';
import u1121 from '!!raw-loader!../../../../../public/img/icons/unicons/wrap-text.svg';
import u1122 from '!!raw-loader!../../../../../public/img/icons/custom/gf-bar-alignment-after.svg';
import u1123 from '!!raw-loader!../../../../../public/img/icons/custom/gf-bar-alignment-before.svg';
import u1124 from '!!raw-loader!../../../../../public/img/icons/custom/gf-bar-alignment-center.svg';
import u1125 from '!!raw-loader!../../../../../public/img/icons/custom/gf-interpolation-linear.svg';
import u1126 from '!!raw-loader!../../../../../public/img/icons/custom/gf-interpolation-smooth.svg';
import u1127 from '!!raw-loader!../../../../../public/img/icons/custom/gf-interpolation-step-after.svg';
import u1128 from '!!raw-loader!../../../../../public/img/icons/custom/gf-interpolation-step-before.svg';
import u1129 from '!!raw-loader!../../../../../public/img/icons/custom/gf-logs.svg';
import u1130 from '!!raw-loader!../../../../../public/img/icons/mono/favorite.svg';
import u1131 from '!!raw-loader!../../../../../public/img/icons/mono/grafana.svg';
import u1132 from '!!raw-loader!../../../../../public/img/icons/mono/heart.svg';
import u1133 from '!!raw-loader!../../../../../public/img/icons/mono/heart-break.svg';
import u1134 from '!!raw-loader!../../../../../public/img/icons/mono/panel-add.svg';
import u1135 from '!!raw-loader!../../../../../public/img/icons/mono/library-panel.svg';

function cacheItem(content: string, path: string) {
  cacheStore[iconRoot + path] = { content, status: 'loaded', queue: [] };
}

export function initIconCache() {
  cacheInitialized = true;

  // This function needs to be called after index.js loads to give the
  // application time to modify __webpack_public_path__ with a CDN path
  const grafanaPublicPath = (window as any).__grafana_public_path__;
  if (grafanaPublicPath) {
    iconRoot = grafanaPublicPath + 'img/icons/';
  }

  cacheItem(u1001, 'unicons/angle-double-down.svg');
  cacheItem(u1002, 'unicons/angle-double-right.svg');
  cacheItem(u1003, 'unicons/angle-down.svg');
  cacheItem(u1004, 'unicons/angle-left.svg');
  cacheItem(u1005, 'unicons/angle-right.svg');
  cacheItem(u1006, 'unicons/angle-up.svg');
  cacheItem(u1007, 'unicons/apps.svg');
  cacheItem(u1008, 'unicons/arrow.svg');
  cacheItem(u1009, 'unicons/arrow-down.svg');
  cacheItem(u1010, 'unicons/arrow-from-right.svg');
  cacheItem(u1011, 'unicons/arrow-left.svg');
  cacheItem(u1012, 'unicons/arrow-random.svg');
  cacheItem(u1013, 'unicons/arrow-right.svg');
  cacheItem(u1014, 'unicons/arrow-up.svg');
  cacheItem(u1015, 'unicons/arrows-h.svg');
  cacheItem(u1016, 'unicons/backward.svg');
  cacheItem(u1017, 'unicons/bars.svg');
  cacheItem(u1018, 'unicons/bell.svg');
  cacheItem(u1019, 'unicons/bell-slash.svg');
  cacheItem(u1020, 'unicons/bolt.svg');
  cacheItem(u1021, 'unicons/book.svg');
  cacheItem(u1022, 'unicons/book-open.svg');
  cacheItem(u1023, 'unicons/brackets-curly.svg');
  cacheItem(u1024, 'unicons/bug.svg');
  cacheItem(u1025, 'unicons/building.svg');
  cacheItem(u1026, 'unicons/calculator-alt.svg');
  cacheItem(u1027, 'unicons/calendar-alt.svg');
  cacheItem(u1028, 'unicons/camera.svg');
  cacheItem(u1029, 'unicons/channel-add.svg');
  cacheItem(u1030, 'unicons/chart-line.svg');
  cacheItem(u1031, 'unicons/check.svg');
  cacheItem(u1032, 'unicons/check-circle.svg');
  cacheItem(u1033, 'unicons/circle.svg');
  cacheItem(u1034, 'unicons/clipboard-alt.svg');
  cacheItem(u1035, 'unicons/clock-nine.svg');
  cacheItem(u1036, 'unicons/cloud.svg');
  cacheItem(u1037, 'unicons/cloud-download.svg');
  cacheItem(u1038, 'unicons/code-branch.svg');
  cacheItem(u1039, 'unicons/cog.svg');
  cacheItem(u1040, 'unicons/columns.svg');
  cacheItem(u1041, 'unicons/comment-alt.svg');
  cacheItem(u1042, 'unicons/comment-alt-share.svg');
  cacheItem(u1043, 'unicons/comments-alt.svg');
  cacheItem(u1044, 'unicons/compass.svg');
  cacheItem(u1045, 'unicons/copy.svg');
  cacheItem(u1046, 'unicons/cube.svg');
  cacheItem(u1047, 'unicons/dashboard.svg');
  cacheItem(u1048, 'unicons/database.svg');
  cacheItem(u1049, 'unicons/document-info.svg');
  cacheItem(u1050, 'unicons/download-alt.svg');
  cacheItem(u1051, 'unicons/draggabledots.svg');
  cacheItem(u1052, 'unicons/edit.svg');
  cacheItem(u1053, 'unicons/ellipsis-v.svg');
  cacheItem(u1054, 'unicons/envelope.svg');
  cacheItem(u1055, 'unicons/exchange-alt.svg');
  cacheItem(u1056, 'unicons/exclamation-triangle.svg');
  cacheItem(u1057, 'unicons/external-link-alt.svg');
  cacheItem(u1058, 'unicons/eye.svg');
  cacheItem(u1059, 'unicons/eye-slash.svg');
  cacheItem(u1060, 'unicons/file-alt.svg');
  cacheItem(u1061, 'unicons/file-blank.svg');
  cacheItem(u1062, 'unicons/filter.svg');
  cacheItem(u1063, 'unicons/folder.svg');
  cacheItem(u1064, 'unicons/folder-open.svg');
  cacheItem(u1065, 'unicons/folder-plus.svg');
  cacheItem(u1066, 'unicons/folder-upload.svg');
  cacheItem(u1067, 'unicons/forward.svg');
  cacheItem(u1068, 'unicons/graph-bar.svg');
  cacheItem(u1069, 'unicons/history.svg');
  cacheItem(u1070, 'unicons/home-alt.svg');
  cacheItem(u1071, 'unicons/import.svg');
  cacheItem(u1072, 'unicons/info.svg');
  cacheItem(u1073, 'unicons/info-circle.svg');
  cacheItem(u1074, 'unicons/key-skeleton-alt.svg');
  cacheItem(u1075, 'unicons/keyboard.svg');
  cacheItem(u1076, 'unicons/link.svg');
  cacheItem(u1077, 'unicons/list-ul.svg');
  cacheItem(u1078, 'unicons/lock.svg');
  cacheItem(u1079, 'unicons/minus.svg');
  cacheItem(u1080, 'unicons/minus-circle.svg');
  cacheItem(u1081, 'unicons/mobile-android.svg');
  cacheItem(u1082, 'unicons/monitor.svg');
  cacheItem(u1083, 'unicons/pause.svg');
  cacheItem(u1084, 'unicons/pen.svg');
  cacheItem(u1085, 'unicons/play.svg');
  cacheItem(u1086, 'unicons/plug.svg');
  cacheItem(u1087, 'unicons/plus.svg');
  cacheItem(u1088, 'unicons/plus-circle.svg');
  cacheItem(u1089, 'unicons/power.svg');
  cacheItem(u1090, 'unicons/presentation-play.svg');
  cacheItem(u1091, 'unicons/process.svg');
  cacheItem(u1092, 'unicons/question-circle.svg');
  cacheItem(u1093, 'unicons/repeat.svg');
  cacheItem(u1094, 'unicons/rocket.svg');
  cacheItem(u1095, 'unicons/save.svg');
  cacheItem(u1096, 'unicons/search.svg');
  cacheItem(u1097, 'unicons/search-minus.svg');
  cacheItem(u1098, 'unicons/search-plus.svg');
  cacheItem(u1099, 'unicons/share-alt.svg');
  cacheItem(u1100, 'unicons/shield.svg');
  cacheItem(u1101, 'unicons/signal.svg');
  cacheItem(u1102, 'unicons/signin.svg');
  cacheItem(u1103, 'unicons/signout.svg');
  cacheItem(u1104, 'unicons/sitemap.svg');
  cacheItem(u1105, 'unicons/slack.svg');
  cacheItem(u1106, 'unicons/sliders-v-alt.svg');
  cacheItem(u1107, 'unicons/sort-amount-down.svg');
  cacheItem(u1108, 'unicons/sort-amount-up.svg');
  cacheItem(u1109, 'unicons/square-shape.svg');
  cacheItem(u1110, 'unicons/star.svg');
  cacheItem(u1111, 'unicons/step-backward.svg');
  cacheItem(u1112, 'unicons/sync.svg');
  cacheItem(u1113, 'unicons/table.svg');
  cacheItem(u1114, 'unicons/tag-alt.svg');
  cacheItem(u1115, 'unicons/times.svg');
  cacheItem(u1116, 'unicons/trash-alt.svg');
  cacheItem(u1117, 'unicons/unlock.svg');
  cacheItem(u1118, 'unicons/upload.svg');
  cacheItem(u1119, 'unicons/user.svg');
  cacheItem(u1120, 'unicons/users-alt.svg');
  cacheItem(u1121, 'unicons/wrap-text.svg');
  cacheItem(u1122, 'custom/gf-bar-alignment-after.svg');
  cacheItem(u1123, 'custom/gf-bar-alignment-before.svg');
  cacheItem(u1124, 'custom/gf-bar-alignment-center.svg');
  cacheItem(u1125, 'custom/gf-interpolation-linear.svg');
  cacheItem(u1126, 'custom/gf-interpolation-smooth.svg');
  cacheItem(u1127, 'custom/gf-interpolation-step-after.svg');
  cacheItem(u1128, 'custom/gf-interpolation-step-before.svg');
  cacheItem(u1129, 'custom/gf-logs.svg');
  cacheItem(u1130, 'mono/favorite.svg');
  cacheItem(u1131, 'mono/grafana.svg');
  cacheItem(u1132, 'mono/heart.svg');
  cacheItem(u1133, 'mono/heart-break.svg');
  cacheItem(u1134, 'mono/panel-add.svg');
  cacheItem(u1135, 'mono/library-panel.svg');
}
